// DON'T FORMAT ON SAVE. TRAILING SPACES ARE BEING USED FOR LINE BREAKS

export const typesOfRolesInTowerHunt = {
  order: 0,
  name: "Types of roles in Tower Hunt",
  subcategory: "Review roles & permissions",
  markdown: `# Types of roles in Tower Hunt
  Roles determine what people can see and do with Tower Hunt teams. Everyone is able to work in Tower Hunt and access key features. There are also administrative roles that are designed for people responsible for managing billing and settings for teams.

  {% callout type="info" %}
  **Note:** Learn more about {% inlineRouterLink articleId="permissions-by-role-in-tower-hunt" %}permissions broken down by role type{% /inlineRouterLink %} in Tower Hunt.
  {% /callout %}

  ## Roles in Tower Hunt

  Below is a complete list of the roles available on Tower Hunt:

  - **Primary owner**
  A Tower Hunt team has a single {% inlineRouterLink %}Primary owner{% /inlineRouterLink %}. Only this person can {% inlineRouterLink %}delete the team{% /inlineRouterLink %} or {% inlineRouterLink %}transfer ownership{% /inlineRouterLink %} to someone else.
  - **Billing member**
  {% inlineRouterLink %}Primary owners can assign billing members{% /inlineRouterLink %}. They can make their respective payment methods and payout accounts available for team use. They can also {% inlineRouterLink %}make the team pay for member usage{% /inlineRouterLink %}.
  - **Full member**
  Members have access to datapoints shared with the team and can choose to {% inlineRouterLink %}enable safezone sharing rules{% /inlineRouterLink %} or {% inlineRouterLink %}leave the team{% /inlineRouterLink %}.
  `,
};
